import React, { Component, useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
// import BrandAdd from "./BrandAdd"
import { Link } from "react-router-dom";

//Table
import MUIDataTable from "mui-datatables";

import EditIcon from "@material-ui/icons/Edit";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import LockIcon from "@material-ui/icons/Lock";
import PasswordIcon from "@material-ui/icons/Password";
import { Tooltip, Grid } from "@material-ui/core";

import SweetAlert from "react-bootstrap-sweetalert";

import axios from "axios";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { connect } from "react-redux";

import { setGenericUser } from "../../../actions/genericUserAction";
import { setShowroomUser } from "../../../actions/showroomUserAction";
import { setBrand } from "../../../actions/BrandAction";

const Users = ({
  history,
  server,
  access_token,
  setGenericUser,
  setShowroomUser,
  showroom_user,
  generic_user,
  brands,
  setBrand,
}) => {

  const [temp, setTemp] = useState(generic_user);
  const [temp2, setTemp2] = useState(showroom_user);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    getGenericUsers();
    getShowroomUsers();
    getAllBrands();
  }, []);

  useEffect(() => {

    setTemp(generic_user);
    setTemp2(showroom_user);

  }, [generic_user, showroom_user]);

  const getAllBrands = () => {

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .get('https://backend.arihantauto.in/' + "api/brand", config)
      .then((response) => {
        setBrand(response.data);
      })
      .catch((error) => {});
  };

  const getGenericUsers = () => {

    setLoading(true);
    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .get('https://backend.arihantauto.in/' + "api/user/generic", config)
      .then((response) => {
        setGenericUser(response.data);
      })
      .catch((error) => {});
  };

  const getShowroomUsers = () => {

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .get('https://backend.arihantauto.in/' + "api/user/showroom", config)
      .then((response) => {
        setShowroomUser(response.data);
        setLoading(false);
      })
      .catch((error) => {});
  };

  const deleteCall = (ids) => {
    
    setAlert(
      <SweetAlert
        warning
        title="Are you sure you want to delete this user?"
        onConfirm={() => deleteRecord(ids)}
        onCancel={hideAlert}
        confirmBtnBsStyle="danger"
        cancelBtnStyle={{ color: "red" }}
        confirmBtnText="Delete!"
        cancelBtnText="Cancel"
        showCancel
      >
        This action cannot be reverted
      </SweetAlert>
    );
  };

  const deleteRecord = (ids) => {
   
    const parameter = {
      _method: "delete",
    };
    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .post('https://backend.arihantauto.in/' + `api/user/${ids}`, parameter, config)
      .then((response) => {

        hideAlert();
        getGenericUsers();
        getShowroomUsers();
        deleted();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleted = () => {
    setAlert(
      <SweetAlert success title="Deleted" onConfirm={hideAlert}>
        User deleted
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const columns_1 = [
    {
      name: "id",
      label: "Id",
      options: {
        display: true,
        filter: true,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Billing Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "owner_name",
      label: "Owner Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "district",
      label: "District",
      options: {
        display:false,
        filter: true,
        sort: false,
      },
    },
    {
      name: "username",
      label: "Username",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "whatsapp_no",
      label: "Whatsapp",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "phone_no",
      label: "Phone",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "district",
      label: "District",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        download:false,
        customBodyRender: (ids, tableMeta, updateValue) => {
          return (
            <>
              <Tooltip title="Edit" placement="top" arrow>
                <IconButton
                  onClick={() =>
                    history.push(
                      "/User/Generic/Edit",
                      generic_user.filter((item) => {
                        return item.id === ids;
                      })
                    )
                  }
                  aria-label="Edit"
                  style={{
                    backgroundColor: "#00ACC1",
                    padding: 5,
                    marginRight: 5,
                    color: "#ffffff",
                  }}
                  className="Edit"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Change Password" placement="top" arrow>
                <IconButton
                  onClick={() =>
                    history.push(
                      "/password",
                      generic_user.filter((item) => {
                        return item.id === ids;
                      })
                    )
                  }
                  aria-label="Change Password"
                  style={{
                    backgroundColor: "rgb(219, 225, 36)",
                    padding: 5,
                    marginRight: 5,
                    color: "#ffffff",
                  }}
                  className="Change Password"
                >
                  <PasswordIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete" placement="top" arrow>
                <IconButton
                  onClick={() => deleteCall(ids)}
                  aria-label="delete"
                  style={{
                    backgroundColor: "#FF0A37",
                    padding: 5,
                    marginRight: 5,
                    color: "#ffffff",
                  }}
                  className="Delete"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  const columns_2 = [
    {
      name: "id",
      label: "Id",
      options: {
        display: true,
        filter: false,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Billing Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "owner_name",
      label: "Owner Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "district",
      label: "District",
      options: {
        display:false,
        filter: true,
        sort: false,
      },
    },
    {
      name: "username",
      label: "Username",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "whatsapp_no",
      label: "Whatsapp",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "phone_no",
      label: "Phone",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "brand_id",
      label: "Brand",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (ids, tableMeta, updateValue) => {
          if (ids) {
            return <>{brands.filter((item) => item.id == ids)[0]?.name}</>;
          }
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,        
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        download:false,
        customBodyRender: (ids, tableMeta, updateValue) => {
          return (
            <>
              <Tooltip title="Edit" placement="top" arrow>
                <IconButton
                  onClick={() =>
                    history.push(
                      "User/showroom/edit",
                      showroom_user.filter((item) => {
                        return item.id === ids;
                      })
                    )
                  }
                  aria-label="Edit"
                  style={{
                    backgroundColor: "#00ACC1",
                    padding: 5,
                    marginRight: 5,
                    color: "#ffffff",
                  }}
                  className="Edit"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Change Password" placement="top" arrow>
                <IconButton
                  onClick={() =>
                    history.push(
                      "/password",
                      showroom_user.filter((item) => {
                        return item.id === ids;
                      })
                    )
                  }
                  aria-label="Change Password"
                  style={{
                    backgroundColor: "rgb(219, 225, 36)",
                    padding: 5,
                    marginRight: 5,
                    color: "#ffffff",
                  }}
                  className="Change Password"
                >
                  <PasswordIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete" placement="top" arrow>
                <IconButton
                  onClick={() => deleteCall(ids)}
                  aria-label="delete"
                  style={{
                    backgroundColor: "#FF0A37",
                    padding: 5,
                    marginRight: 5,
                    color: "#ffffff",
                  }}
                  className="Delete"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "multiselect",
    selectableRows: false,
    print: false,
    download: true,
  };

  return (
    <>
      <div className="dashboardMainDivContainer">
        {alert}
        <div className="card m-3 mt-5">
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', width: '100%' }}>

          <div className="titles">Users</div>

          <div style={{ textAlign: "end", margin: "0px 2px" }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
              <div style={{ position: "absolute", right: 30 }}>

                <Link className="BtnLinkDist" to="/User/AddGeneric">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{backgroundColor:'#F05225', paddingTop:10}}
                    startIcon={<AddCircleIcon />}
                  >
                    Add New Generic User
                  </Button>
                </Link>

                </div>
              </Grid>
              <Grid item xs={12} margin={10}>

              <div style={{ position: "absolute", right: 300, top:80}}>

                <Link className="BtnLinkDist" to="/User/AddShowroom">
                  <Button
                    color="secondary"
                    variant="contained"     
                    style={{ paddingTop:10}}              
                    startIcon={<AddCircleIcon />}
                  >
                    Add New Showroom User
                  </Button>
                </Link>

             </div>
              </Grid>
            </Grid>
          </div>

          </div>
          <div style={{ marginTop: "2%" }}>

          {loading == true ?

<div style={{ marginTop: "2%" }}>
  <Skeleton height={70} />
  <Skeleton count={15} style={{ marginTop: 15 }} />
</div>
:
            <MUIDataTable
              title={"List of All Generic User"}
              data={temp}
              columns={columns_1}
              qer
              options={options}
            />
      }

          </div>
          <div style={{ marginTop: "2%" }}>

          {loading == true ?

<div style={{ marginTop: "2%" }}>
  <Skeleton height={70} />
  <Skeleton count={15} style={{ marginTop: 15 }} />
</div>
:
            <MUIDataTable
              title={"List of all Showroom User"}
              data={temp2}
              columns={columns_2}
              qer
              options={options}
            />

          }
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  showroom_user: state.showroom_user,
  generic_user: state.generic_user,
  brands: state.brand,
});

export default connect(mapStateToProps, {
  setGenericUser,
  setShowroomUser,
  setBrand,
})(Users);
